import VisuallyHidden from "@reach/visually-hidden";
import classNames from "classnames";
import { ButtonBack, ButtonNext, Dot, DotGroup } from "pure-react-carousel";

import styles from "./generic-carousel.module.scss";

import CaretIcon from "../../public/svg/caret.svg";

type Props = {
  dotGroupClass?: string;
  dotClass?: string;
};

export function CarouselDots({ dotGroupClass, dotClass }: Props) {
  return (
    <DotGroup
      className={classNames(dotGroupClass, styles.dotGroup)}
      renderDots={({ currentSlide, totalSlides, visibleSlides, disableActiveDots }) => {
        const dots = [];
        if (
          totalSlides !== undefined &&
          currentSlide !== undefined &&
          visibleSlides !== undefined
        ) {
          for (let i = 0; i < totalSlides; i += visibleSlides) {
            const selected = i >= currentSlide && i < currentSlide + visibleSlides;
            const slide = i >= totalSlides - visibleSlides ? totalSlides - visibleSlides : i;
            dots.push(
              <Dot
                key={i}
                slide={slide}
                disabled={disableActiveDots ? selected : false}
                className={classNames(dotClass, styles.dot)}
              >
                <VisuallyHidden>Slide {slide}</VisuallyHidden>
              </Dot>
            );
          }
        }

        return dots;
      }}
    />
  );
}

interface CarouselButton {
  className?: string;
}

export const PreviousSlideButton = ({ className }: CarouselButton) => (
  <ButtonBack className={classNames([styles.previous, className])}>
    <CaretIcon />
  </ButtonBack>
);

export const NextSlideButton = ({ className }: CarouselButton) => (
  <ButtonNext className={classNames([styles.next, className])}>
    <CaretIcon />
  </ButtonNext>
);
