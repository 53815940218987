import classNames from "classnames";
import { useState } from "react";
import { useId } from "@reach/auto-id";
import {
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from "@reach/listbox";
import VisuallyHidden from "@reach/visually-hidden";
import { FormLabel } from "components/Input/FormLabel";
import { ErrorMessage } from "components/Input/ErrorMessage";

import CaretIcon from "../../public/svg/caret.svg";

import "@reach/listbox/styles.css";
import styles from "./dropdown.module.scss";

export type DropdownProps = {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  children: any;
  label?: React.ReactNode;
  labelSize?: "small" | "large";
  labelVisible?: boolean;
  /** If you're putting a dropdown in a modal, this will probably need to be false */
  popoverPortal?: boolean;
  /** If you use this, you might want to use the same string value for `defaultValue` */
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  name?: string;
  popoverClass?: string;
};

export const Dropdown = ({
  defaultValue,
  value: propsValue,
  onChange,
  children,
  label,
  labelSize = "large",
  labelVisible = true,
  popoverPortal = true,
  placeholder,
  className,
  errorMessage,
  name,
  popoverClass,
}: DropdownProps) => {
  let labelId = `dropdown-label-${useId()}`;
  let [stateValue, setValue] = useState(propsValue ?? defaultValue);
  const value = propsValue ?? stateValue;
  return (
    <div className={className}>
      {!!label && labelVisible === true && (
        <FormLabel id={labelId} size={labelSize}>
          {label}
        </FormLabel>
      )}
      {!!label && !labelVisible && <VisuallyHidden id={labelId}>{label}</VisuallyHidden>}
      {name && <input type="hidden" name={name} value={value} />}
      <ListboxInput
        aria-labelledby={labelId}
        value={value}
        onChange={(value) => {
          setValue(value);
          !!onChange && onChange(value);
        }}
      >
        <ListboxButton
          className={classNames({
            [styles.button]: true,
            [styles.placeholderSelected]: !!placeholder && value === placeholder,
            [styles.inputError]: !!errorMessage,
          })}
          arrow={<CaretIcon />}
        />
        <ListboxPopover className={`${styles.popover} ${popoverClass}`} portal={popoverPortal}>
          <ListboxList className={styles.list}>
            {placeholder && (
              <ListboxOption value={placeholder} className={styles.placeholder}>
                {placeholder}
              </ListboxOption>
            )}
            {children}
          </ListboxList>
        </ListboxPopover>
      </ListboxInput>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};
